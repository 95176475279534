<template>
  <div class="login-container">
    <el-form
      :model="ruleForm"
      status-icon
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm login-form"
    >
      <div class="title-container">
        <h3 class="title">系统登录</h3>
      </div>
      <el-form-item label="账号" prop="checkPass">
        <el-input
          type="text"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm.pass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-button
        type="primary"
        @click="submitForm(ruleForm)"
        style="width: 100%; margin-bottom: 30px;"
        >登录</el-button
      >
    </el-form>
  </div>
</template>
<script>
import { setLocal,getLocal } from '@/api/local'
import { _loginMy } from '@/api/login.js'
export default {
  data () {
    return {
      input: '',
      ruleForm: {
        pass: null,
        checkPass: null
      }
    }
  },
  created () {},
  mounted() {
    console.log(getLocal('token'));
  },
  methods: {
    async submitForm (formName) {
      let obj = {
        username: formName.checkPass,
        password: formName.pass
      }
      const res = await _loginMy(obj)
      if (res.data.code == 1) {
        // 存 token
        setLocal('token', res.data.result.token)
        // vuex处理
        this.$store.commit('setIsLogin', true)
        this.$router.push('../homePage/homePage')
      } else {
        return
      }
    }
  }
}
</script>
<style lang="less" scoped>
.login-container {
  min-height: 100%;
  width: 100%;
  background-color: #2d3a4b;
  overflow: hidden;
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    .title-container {
      position: relative;
      .title {
        font-size: 26px;
        color: #eee;
        margin: 0 auto 40px auto;
        text-align: center;
        font-weight: 700;
      }
    }
    .btn {
      margin-left: 0;
      ::v-deep .el-form-item .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
::v-deep .el-form-item {
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #454545;
}
::v-deep .el-input__inner {
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 12px 5px 12px 15px;
  color: #fff;
  height: 47px;
  caret-color: #fff;
}
::v-deep .el-button--medium {
  margin-bottom: 0 !important;
}
::v-deep .el-form-item__label {
  height: 40px;
  line-height: 50px;
}
</style>
