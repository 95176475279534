import _fetch from "./request";
// export function login(data){
//   return _fetch.post('/admin/login',data)
// }

export function _loginMy(data) {
  return _fetch({
    url: '/admin/login',
    method: 'post',
    data
  })
}